<template >
  <div class="container" ref="wrappers7bbs">
    <van-row>
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item v-model="page.filterData.typeId" :options="pclassOption" @change="onSearch" />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search v-model="page.filterData.title" @search="onSearch" placeholder="请输入搜索关键词" />
      </van-col>
    </van-row>

    <van-row style="margin: 5px">
      <van-col span="24">
        <div class="product-proptext">
          <div class="btn-box" v-if="systemType == 1">
            <van-button type="info" round block @click="shareBBS()">分享你的销售思路,赢取能量值</van-button>
          </div>
          <div class="btn-box" v-if="systemType == 2">
            <van-button type="info" round block @click="shareBBS()">分享你的学习感悟,赢取能量值</van-button>
          </div>
        </div>
      </van-col>
    </van-row>

    <van-cell>
      <van-col span="4">
        <van-tag round size="large" :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)">默认</van-tag>
      </van-col>
      <van-col span="4">
        <van-tag round size="large" :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)">最新</van-tag>
      </van-col>
      <van-col span="4">
        <van-tag round size="large" :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)">最热</van-tag>
      </van-col>
      <van-col span="4">
        <van-tag round size="large" :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)">最早</van-tag>
      </van-col>
    </van-cell>

    <van-cell v-if="page.filterData.topicId > 0 ||
      page.filterData.productId != '00000000-0000-0000-0000-000000000000'
      ">
      <van-col span="24">
        <van-tag v-if="page.filterData.topicId > 0" closeable round size="large" color="#9d1d22" @close="closetopic">
          {{ topicName }}
        </van-tag>

        <van-tag v-if="page.filterData.productId != '00000000-0000-0000-0000-000000000000'
          " closeable round size="large" color="#9d1d22" @close="closeproduct">
          {{ productName }}
        </van-tag>
      </van-col>
    </van-cell>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">
        <van-cell-group v-for="(item,index) in articles" :key="item.id.toString()">
          <van-cell @click="toDetail(item.id.toString())" :border="false">
            <van-col span="3">
              <van-image style="float: left" round width="41px" height="41px" fit="fill" :src="item.headImgurl" />
            </van-col>

            <van-col span="21">
              <div style=" width: 100%;">
                <a style="
                  float: left;
                  color: #069;
                  font-size: 16px;
                  font-weight: bold;
                 
                ">

                  {{ item.trueName }}
                </a>
                <userIcon :iconstring="item.icon"></userIcon>
                <a style="margin-left: 10px;color: #069; font-size: 15px; font-weight: bold">
                  {{ item.departName }}
                </a>
              </div>
              <div style="float: left; color: #696969">
                {{ item.creationTime | relTime }}
              </div>
              <div style="float: left; color: #696969; margin-left: 13px">
                浏览{{ item.readCount }}人次
              </div>
            </van-col>

            <van-col span="2"></van-col>
            <van-col span="22">
              <div>
                <h3 style="display: inline-block">{{ item.title }}</h3>

                &nbsp;
                <van-tag v-if="item.audioUrl && item.audioUrl.length > 5" mark plain color="#9d1d22">有音频
                </van-tag>
                &nbsp;&nbsp;
                <van-tag v-if="item.videoUrl && item.videoUrl.length > 5" mark plain color="#9d1d22">有视频
                </van-tag>
              </div>
              <!-- <div class="acontent van-multi-ellipsis--200">
              <div class="bbscontent" v-html="item.detailsHtml"></div>
              <div
                class="showqw"
                style="
                  position: absolute;
                  height: 30px;
                  color: rgb(0, 102, 153);
                  font-size: 16px;
                  font-weight: bold;
                  width: 100%;
                  bottom: 0px;
                  background: white;
                "
              >
                点击查看全文
              </div>
            </div> -->
            </van-col>
          </van-cell>
          <van-cell :border="false">
            <!--话题-->
            <van-col span="2"></van-col>
            <van-col span="22">
              <div style="margin-top: 10px">
                <div style="display: inline-block" v-if="item.typeName != undefined && item.typeName.length > 0"
                  @click="toType(item)">
                  <van-tag color="rgb(255 242 219)" text-color="#9d1d22" size="large">#{{ item.typeName }}#
                  </van-tag>

                  <span style="width: 10px; display: inline-block"></span>
                </div>
                <div style="display: inline-block" v-if="item.topicName != undefined && item.topicName.length > 0
                  " @click="toTopic(item)">
                  <van-tag size="large" color="rgb(255 242 219)" text-color="#9d1d22">#{{ item.topicName }}#
                  </van-tag>
                  <span style="width: 10px; display: inline-block"></span>
                </div>
                <van-tag @click="toProduct(item)" v-if="item.productName != undefined && item.productName.length > 0
                  " size="large" color="rgb(255 242 219)" text-color="#9d1d22">{{ item.productName }}
                </van-tag>
              </div>
            </van-col>
          </van-cell>
          <van-cell>
            <van-col span="24">
              <van-icon name="/image/whocansee.png" size="15" />
              <a style="margin-left: 10px;color: #069; font-size: 15px; font-weight: bold">
                {{ item.hrDepartmentName }}
              </a>
              <a v-if="item.canEditeDepartment || item.canEdite" style="clear: both" @click="showShareDeaprament(item,index)">
                <van-icon name="share-o" />
                <span>分享</span>
              </a>
            </van-col>
          </van-cell>

        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <van-popup
            v-model="isshowShare"
            closeable
            position="bottom"
            :style="{ height: '25%' }"
          >
            <van-nav-bar title="选择分享到的部门" />
            {{currentBBS.title}}
            <SelectDepartment
          @ChangecheckedDepartment="ChangecheckedDepartment"
        />
        <van-button type="info" round block @click="ChangeBBSDepartment()">确认</van-button>
          </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getBbsList,
  getBBSTypeList,
  getBBSTopicName,
  getBBSTopicS7List,
  UpdateHRDepartmentId,
} from "@/api/bbs";
import { getArticleTypeList } from "@/api/article";
import { getProductName } from "@/api/product";
import { getCenterList } from "@/api/department";
import UserIcon from "@/views/components/userIcon";
import SelectDepartment from "@/views/bbs/components/selectDepartment";

export default {
  props: {
    systemType: Number,
  },
  data() {
    return {
      refreshing: false,
      pclassOption: [{ text: "全部", value: 0 }],
      s7TopicIdOption: [],
      departmentOption: [],
      s7topicList: [],
      s7selectList: [],
      title: "进阶S7攻略",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      topicName: "",
      productName: "",
      isshowShare:false,
      currentBBS:{},
      currentIndex:0,
      departmentId:0,
      scroll: 0,
      page: {
        sorterType: 0,
        current: 1,
        pageSize: 10,
        fromType: 1,
        filterData: {
          typeId: 0,
          systemType: 0,
          productId: "00000000-0000-0000-0000-000000000000",
          topicId: 0,
          title: "",
          departmentId: 0,
          s7TopicId: "",
        },
      },
    };
  },

  activated() {
    try {
      this.$refs.wrappers7bbs.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },

  created() {
    this.initData();
  },
  updated() {
    this.showQW();
  },
  components: {
    UserIcon,
    SelectDepartment,
  },
  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.filterData.systemType = this.systemType;
      this.page.sorterType = 0;
      let productId = this.$route.query.productId;
      if (productId === undefined) {
        productId = "00000000-0000-0000-0000-000000000000";
      }
      this.page.filterData.productId = productId;

      if (productId != "00000000-0000-0000-0000-000000000000") {
        let nameReuslt = await getProductName(productId);
        this.productName = nameReuslt.data.data;
      }

      let topicId = this.$route.query.topicid;

      if (topicId === undefined) {
        topicId = 0;
      }
      topicId = parseInt(topicId);
      this.page.filterData.topicId = topicId;

      if (topicId > 0) {
        let nameReuslt = await getBBSTopicName(topicId);

        this.topicName = nameReuslt.data.data;
      }

      let typeId = this.$route.query.typeid;

      if (typeId === undefined) {
        typeId = 0;
      }
      typeId = parseInt(typeId);
      this.page.filterData.typeId = typeId;
      this.pclassOption = [{ text: "全部", value: 0 }];


      if (this.systemType == 0) {
        let pClassList = await getBBSTypeList();
        pClassList.data.data.map((x) =>
          this.pclassOption.push({ text: x.typeName, value: x.id })
        );
      }
      else if (this.systemType > 0) {
        let subTypeList = await getArticleTypeList({
          current: 1,
          pageSize: 100,
          filterData: {
            parentID: 4000 + this.systemType,
          },
        });
        if (subTypeList.data.data.length > 0) {

          subTypeList.data.data.map((x) =>
            this.pclassOption.push({ text: x.typeName, value: x.id })
          );
        }

      }


      this.page.filterData.typeId = typeId;
      let that = this;
      let s7topicList = await getBBSTopicS7List({
        current: 1,
        pageSize: 20,
      });
      this.s7topicList = [];
      this.s7TopicIdOption = [{ text: "分类全部", value: "" }];
      s7topicList.data.data.map((x) =>
        this.s7TopicIdOption.push({ text: x.topicName, value: x.id.toString() })
      );
      for (let x of s7topicList.data.data) {
        that.s7topicList.push(x);
      }

      let pDepartmentist = await getCenterList();
      this.departmentOption = [{ text: "中心全部", value: 0 }];

      pDepartmentist.data.data.map((x) => {
        if (x.id <= 112) {
          this.departmentOption.push({ text: x.name, value: x.id });
        }
      });

      //let type = this.$route.query.type;
    },
    onSearch() {
      this.articles = [];
      this.finished = false;
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
    changeS7TopicId(s7TopicId) {
      if (this.page.filterData.s7TopicId.indexOf(s7TopicId) < 0) {
        this.s7selectList.push(s7TopicId);
        this.page.filterData.s7TopicId = this.s7selectList.toString();
        this.onSearch();
      } else {
        this.s7selectList.forEach(function (item, index, arr) {
          if (item === s7TopicId) {
            arr.splice(index, 1);
          }
        });
        console.log(this.s7selectList);
        this.page.filterData.s7TopicId = this.s7selectList.toString();
        this.onSearch();
      }
    },

    clearS7TopicId() {
      if (this.page.filterData.s7TopicId != "") {
        this.s7selectList = [];
        this.page.filterData.s7TopicId = this.s7selectList.toString();
        this.onSearch();
      }
    },

    toDetail(id) {
      this.scroll = this.$refs.wrappers7bbs.scrollTop;
      this.$router.push({
        path: "/bbs/detail",
        query: { para: id, type: this.page.filterData.typeId, back: true },
      });
    },
    async shareBBS() {
      this.articles.id;

      this.$router.push({
        path: "/bbs/add",
        query: { systemType: this.systemType },
      });
    },

    toadd() {
      this.$router.push({ path: "/bbs/add" });
    },
    showShareDeaprament(item,index){
      this.currentBBS=item;
      this.currentIndex=index;
      this.isshowShare=true;

    },
    ChangecheckedDepartment(value,name) {
      this.departmentId = value;
      console.log(name);
    },

   async ChangeBBSDepartment() {
      this.currentBBS.hrDepartmentId=this.departmentId;
      let aresult = await UpdateHRDepartmentId(this.currentBBS)
      if(aresult.data.success){
        Toast("修改成功");
        if(aresult.data.data){
          this.articles[this.currentIndex].hrDepartmentName=aresult.data.data;
        }
      }
      
      this.isshowShare=false;
    },

    async onLoad() {

      this.upLoading = true;
      console.log("bbs onLoad");
      if (this.articles.length > 0 && this.page.current == 1) {
        return;
      }
      let aresult = await getBbsList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);

      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    toType(item) {
      if (this.page.filterData.typeId != item.typeId) {
        this.page.filterData.typeId = item.typeId;
        this.onSearch();
      }
    },
    toTopic(item) {
      if (this.page.filterData.topicId != item.topicId) {
        this.topicName = item.topicName;
        this.page.filterData.topicId = item.topicId;
        this.onSearch();
      }
    },
    toProduct(item) {
      if (this.page.filterData.productId != item.productId) {
        this.productName = item.productName;
        this.page.filterData.productId = item.productId;
        this.onSearch();
      }
    },
    closetopic() {
      this.page.filterData.topicId = 0;
      this.onSearch();
    },
    closeproduct() {
      this.page.filterData.productId = "00000000-0000-0000-0000-000000000000";
      this.onSearch();
    },

    showQW() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((element) => {
        if (element.childNodes.length == 2) {
          if (element.childNodes[0].clientHeight > 300) {
            element.childNodes[1].style.display = "block";
          } else {
            element.childNodes[1].style.display = "none";
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  /deep/.van-list__loading {
    height: calc(80vh);
  }
}

// 这个方式兼容所有版本的谷歌浏览器
audio::-webkit-media-controls {
  overflow: hidden !important;
}

audio::-webkit-media-controls-enclosure {
  width: calc(100% + 32px);
  margin-left: auto;
}

audio {
  border-radius: 50px;
}

.bbscontent {
  height: 100%;
}

.acontent {
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }

  /deep/ ol li {
    list-style-position: outside;
  }

  /deep/ img {
    max-width: 33% !important;
    display: block;
    border: 0;
  }

  /deep/ video {
    max-width: 100% !important;
  }
}
</style>        